:root {
  --trolley-color: #5a5a5c;
}

.tram-container {
  border-top: 3px solid var(--trolley-color);
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  container-type: inline-size;
}

.tram {
  max-width: 580px;
  max-height: 113px;
  animation: 16s ease-in-out infinite marquee;
}

@keyframes marquee {
  0% {
    transform: translateX(-100%)scaleX(1);
  }

  49% {
    transform: translateX(100cqw)scaleX(1);
  }

  51% {
    transform: translateX(100cqw)scaleX(-1);
  }

  100% {
    transform: translateX(-100%)scaleX(-1);
  }
}
/*# sourceMappingURL=repo.4a55e16b.css.map */
